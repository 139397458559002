import React, { useContext, useState } from "react";
import { Col, Modal, Row, Button } from "react-bootstrap";
import {
   DateOfBirth,
   DonorId,
   Email,
   Name,
   Password,
   PhoneNumber,
} from "../authComponents";
import {
   useValidEmail,
   useValidPassword,
   useValidRePassword,
   useValidPhoneNumber,
   useValidFirstName,
   useValidLastName,
   useValidDonorId,
} from "../../../hooks/useAuthValidationHook";
import { AuthContext, IAuthContext } from "../../../contexts/auth/authContext";
import { ModalPageNames } from "../../../consts";
import Alert from "react-bootstrap/Alert";
import { CommonModalHeader } from "../styled";
import {
   CrossIconImg,
   DisplayInformationDiv,
   FormGroupPaddingLeft,
   ForwardIconImg,
   NewAccountForm,
   RequiredFieldsDiv,
   SectionHeader,
   StyledDot,
} from "./styled";
import { useMediaQuery } from "react-responsive";
import { ISignUpResult } from "amazon-cognito-identity-js";
//SCREDP-81 Make Donor Account Creation Fields Configurable
import { EnvironmentContext } from "app/contexts/environment/environmentContext";

function NewAccount({
   setPageName,
   onClose,
   setEmailToBeVerified,
   setIsPasswordSection,
}) {
   //SCREDP-81 Make Donor Account Creation Fields Configurable
   const { environmentConfig } = React.useContext(EnvironmentContext);
   const { email, setEmail, emailIsValid } = useValidEmail("");
   const { password, setPassword, passwordIsValid } = useValidPassword("");
   const { rePassword, setRePassword, rePasswordIsValid } =
      useValidRePassword("");
   const { phoneNumber, setPhoneNumber, phoneNumberIsValid } =
      useValidPhoneNumber("");
   const { firstName, setFirstName, firstNameIsValid } = useValidFirstName("");
   const { lastName, setLastName, lastNameIsValid } = useValidLastName("");
   const { donorId, setDonorId, donorIdIsValid } = useValidDonorId("");
   const [dob, setDob] = useState<Date | null>(null);
   const [passwordVisible, isPasswordVisible] = useState<boolean>(false);
   const [rePasswordVisible, isRePasswordVisible] = useState<boolean>(false);
   const [error, setError] = useState<string>("");
   const isLarge: boolean = useMediaQuery({ minWidth: 992 });
   const isPasswordSame: boolean =
      password && rePassword ? password === rePassword : true;

   const isInValid: boolean =
      !emailIsValid ||
      email.length === 0 ||
      !passwordIsValid ||
      password.length === 0 ||
      rePassword.length === 0 ||
      !rePasswordIsValid ||
      //SCREDP-81 Make Donor Account Creation Fields Configurable
      (environmentConfig?.phoneNumberRequired && (phoneNumber.length === 0 || !phoneNumberIsValid )) ||
      (environmentConfig?.firstNameRequired && (firstName.length === 0 || !firstNameIsValid )) ||
      (environmentConfig?.lastNameRequired && (lastName.length === 0 || !lastNameIsValid )) ||
      donorId.length === 0 ||
      !donorIdIsValid ||
      //SCREDP-81 Make Donor Account Creation Fields Configurable
      (environmentConfig?.dOBRequired && ( !dob )) ||
      !isPasswordSame;

   const authContext = useContext<IAuthContext>(AuthContext);
   const signUpClicked = async (): Promise<void> => {
      try {
         const response: ISignUpResult = await authContext.signUpWithEmail(
            email,
            password,
            donorId.replaceAll("-", ""),
            firstName,
            lastName,
            dob!=null? dob!.toISOString().split("T")[0]!: "",
            phoneNumber.replace(/\D/g, "")
         );
         if (response) {
            setEmailToBeVerified(email);
            setPageName(ModalPageNames.VERIFYING_ACCOUNT);
            setIsPasswordSection(false);
         }
      } catch (err: any) {
         if (err.code === "UserNotConfirmedException") {
            await resendCodeConfirmation();
         } else {
            setError(
               "Sorry, the details you have entered could not be validated. Please verify the information is correct and try again.",
            );
         }
      }
   };

   const resendCodeConfirmation = async (): Promise<void> => {
      try {
         await authContext.resendCode(email);
         setEmailToBeVerified(email);
         setPageName(ModalPageNames.VERIFYING_ACCOUNT);
         setIsPasswordSection(false);
      } catch (err: any) {
         console.error(err.message);
      }
   };

   return (
      <>
         <Modal.Header></Modal.Header>
         <Modal.Body>
            <CommonModalHeader className="desktop-header-css">
               <h5>
                  <b>Create an account</b>
               </h5>
            </CommonModalHeader>
            {error && <Alert variant="danger">{error}</Alert>}
            <DisplayInformationDiv>
               In order to create a portal login account matched to your
               existing donor record, please complete the information fields
               below and click Continue
            </DisplayInformationDiv>
            <NewAccountForm>
               <Row>
                  <FormGroupPaddingLeft
                     as={Col}
                     xs="12"
                     lg="6"
                     className={isLarge ? "" : "mb-4"}
                  >
                     <SectionHeader> Account Details</SectionHeader>
                     <DonorId
                        donorId={donorId}
                        donorIdIsValid={donorIdIsValid}
                        setDonorId={setDonorId}
                     />
                     <Email
                        emailIsValid={emailIsValid}
                        setEmail={setEmail}
                        placeholderValue="Email Address"
                        isRequired
                     />
                     <Password
                        label="Password"
                        passwordIsValid={passwordIsValid && isPasswordSame}
                        setPassword={setPassword}
                        setError={setError}
                        passwordVisible={passwordVisible}
                        isPasswordVisible={isPasswordVisible}
                        isPasswordSame={isPasswordSame}
                        isRequired
                        isPasswordShowRequired
                     />
                     <Password
                        label="Re-enter Password"
                        passwordIsValid={rePasswordIsValid && isPasswordSame}
                        setPassword={setRePassword}
                        setError={setError}
                        passwordVisible={rePasswordVisible}
                        isPasswordVisible={isRePasswordVisible}
                        isPasswordSame={isPasswordSame}
                        isRequired
                        isPasswordShowRequired
                     />
                  </FormGroupPaddingLeft>
                  <FormGroupPaddingLeft as={Col} xs="12" lg="6">
                     <SectionHeader> Personal Details</SectionHeader>
                     <Name
                        name={firstName}
                        nameIsValid={firstNameIsValid}
                        setName={setFirstName}
                        placeholderValue="First Name"
                        //SCREDP-81 Make Donor Account Creation Fields Configurable
                        isOptional={!environmentConfig?.firstNameRequired}
                     />
                     <Name
                        name={lastName}
                        nameIsValid={lastNameIsValid}
                        setName={setLastName}
                        placeholderValue="Last Name"
                        //SCREDP-81 Make Donor Account Creation Fields Configurable
                        isOptional={!environmentConfig?.lastNameRequired}
                     />
                     <DateOfBirth 
                        dob={dob} 
                        setDob={setDob} 
                        //SCREDP-81 Make Donor Account Creation Fields Configurable
                        isOptional={!environmentConfig?.dOBRequired}
                     />
                     <PhoneNumber
                        phoneNumberIsValid={phoneNumberIsValid}
                        setPhoneNumber={setPhoneNumber}
                        phoneNumber={phoneNumber}
                        placeholderValue="Phone Number"
                        //SCREDP-81 Make Donor Account Creation Fields Configurable
                        isOptional={!environmentConfig?.phoneNumberRequired}
                     />
                  </FormGroupPaddingLeft>
               </Row>
               <Row>
                  <RequiredFieldsDiv
                     className={`${
                        isLarge
                           ? "desktop-required-css mb-4"
                           : "mobile-required-css mb-5"
                     }`}
                  >
                     <div>
                        <StyledDot />
                        <span>Required Fields</span>
                     </div>
                  </RequiredFieldsDiv>
               </Row>
               <div
                  className={`footer-section-css ${
                     isLarge ? "desktop-footer-css" : "mobile-footer-css"
                  }`}
               >
                  <Button onClick={onClose} className="btn-css">
                     Cancel <CrossIconImg />
                  </Button>
                  <Button
                     disabled={isInValid}
                     onClick={signUpClicked}
                     className="btn-css"
                  >
                     Continue <ForwardIconImg />
                  </Button>
               </div>
            </NewAccountForm>
         </Modal.Body>
      </>
   );
}

export default NewAccount;
