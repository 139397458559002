import React from "react";
// == SCREDP-95 Allow Admin Customization of Text Displayed on Various Donor Portal Pages ==
import { EnvironmentContext } from "app/contexts/environment/environmentContext";
import { isStringNullUndefinedOrEmpty } from "app/pages/MyDonationPage/utils/utils";

function AppointmentText({ isSingleAppointmentType, timesCount }) {
   // == SCREDP-95 Allow Admin Customization of Text Displayed on Various Donor Portal Pages ==
   const { environmentConfig } = React.useContext(EnvironmentContext);
   return (
      <>
         {timesCount > 0 &&
            (<p className="appointment-text">
               {/* SCREDP-95 Allow Admin Customization of Text Displayed on Various Donor Portal Pages */}
               {
                  isStringNullUndefinedOrEmpty(environmentConfig?.viewDriveSlotsMessage)
                     ?!isSingleAppointmentType
                        ? `Now select an Appointment Type and then find a time that works for you!`
                        : `Now let's find a time that works for you!`
                     :environmentConfig?.viewDriveSlotsMessage
               }
            </p>)
         }


      </>

   );
}

export default AppointmentText;