import React, { useState } from "react";
import { StyledErrorModal } from "./styled";
import { Modal } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import Button from "react-bootstrap/Button";

interface IModalInterface {
   show: boolean;
   setModalShow: (_: boolean) => void;
   message: string;
}
export const ErrorModal: React.FunctionComponent<IModalInterface> = ({
   show,
   setModalShow,
   message
}: IModalInterface) => {
   const [modalSize, setModalSize] = useState<"sm" | "lg" | "xl" | undefined>(
      undefined,
   );
   const isMobile: boolean = useMediaQuery({ maxWidth: 767 });

   const onClose = (): void => {
      setModalShow(false);
   };

   const onOpenModal = (): void => {
      setModalSize(undefined);
   };

   return (
      <StyledErrorModal
         size={modalSize}
         show={show}
         onHide={onClose}
         onEnter={onOpenModal}
         aria-labelledby="contained-modal-title-vcenter"
         backdrop="static"
         keyboard={false}
         fullscreen={isMobile}
         className={isMobile ? "mobile-css" : "desktop-css"}
         centered
      >

         <div className="top-modal-border"></div>
         <Modal.Header closeButton><h1 className="modal-header">Oops!</h1></Modal.Header>
         <Modal.Body>
            <div className="black-bordered-box">
               <span>{message}</span>
            </div>
            <div className="button-container">
               <Button
                  className="continue"
                  variant="danger"
                  onClick={() => {setModalShow(false); window.location.reload();}}
               >
                  <b>Close</b>
               </Button>
            </div>
         </Modal.Body>
      </StyledErrorModal>
   );
};

export default ErrorModal;
