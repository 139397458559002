import styled from "styled-components";
import { Modal } from "react-bootstrap";
import { PRIMARY_COLORS } from "../../consts/colors";

export const StyledErrorModal = styled(Modal)`
   .modal-content {
      font-family: "Roboto", "Helvetica Neue", sans-serif;
      border-top: none;
      border-radius: 0.25rem;
      .top-modal-border {
         width: 100%;
         height: 5px;
         background-color: ${PRIMARY_COLORS.FIRE_ENGINE_RED};
         border-radius: 0.5rem;
      }
      .modal-header {
         border-bottom: none;
      }
   }
   &.desktop-css {
      .modal-body {
         margin: 0px 20px 20px 20px;
      }
   }

   .black-bordered-box {
      border: 2px solid black;
      background-color: white; 
      color: black;            
      padding: 10px;           
      margin-bottom: 20px;      
      text-align: left;         
      line-height: 1.5;     
      font-weight: bold;  
      white-space: pre-line;
   }

   .button-container {
      display: flex;
      justify-content: flex-end; 
      margin-top: 10px;          
   }

   .continue-btn {
      padding: 10px 15px;     
   }

   h1.modal-header {
      color: red;
      font-weight: bolder;
      font-size: x-large;
      padding: 0px !important;
   }
`;
