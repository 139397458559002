import React, { useEffect, useState, useRef } from "react";
import SignInOrNewAppointment from "../SignInOrNewAppointment";
import SignInComponent from "../SignInComponent";
import NewAccount from "../NewAccount";
import VerificationComponent from "../VerficationComponent";
import { ModalPageNames } from "../../../consts";
import RequestAppointment from "../RequestAppointment";
import { AuthenticationModal } from "./styled";
import ForgotPassword from "../ForgotPassword";
import { useMediaQuery } from "react-responsive";
import AppointmentConfirmationModal from "../AppointmentConfirmationModal";
import ChangePasswordModal from "../ChangePassword";

interface IModalInterface {
   show: boolean;
   setModalShow: (_: boolean) => void;
   pageNameSent: string;
   confirmedAptDetails?: any;
   setIsCancelConfirmed?: (_: boolean) => void;
   setBookAppointmentOnSignIn?: any; // SCREDP-12 - Improve the Account Creation/Login Workflow When Booking an Appointment
}

export const SignInModal: React.FunctionComponent<IModalInterface> = ({
   show,
   setModalShow,
   pageNameSent,
   confirmedAptDetails,
   setIsCancelConfirmed,
   setBookAppointmentOnSignIn
}: IModalInterface) => {
   const [pageName, setPageName] = useState<string>("");
   const [modalSize, setModalSize] = useState<"sm" | "lg" | "xl" | undefined>(
      undefined,
   );
   const [emailToBeVerified, setEmailToBeVerified] = useState<string>("");
   const [isPasswordSection, setIsPasswordSection] = useState<boolean>(false);
   const [passwordResetRequired, setPasswordResetRequired] = useState<boolean>(false); // Store whether a password reset is required (SCREDP-55)
   const isMobile: boolean = useMediaQuery({ maxWidth: 767 });
   const storedRememberMe = localStorage.getItem('rememberMe');
   // AEP-64: definition of the remember me functionality
   const [rememberMe, setRememberMe] = useState<boolean>(storedRememberMe ? storedRememberMe === "true" : false);
   const storedEmail = localStorage.getItem('email');
   const [rememberedEmail, setRememberedEmail] = useState(storedEmail ? storedEmail : "");
   const emailInputRef = useRef<HTMLInputElement>(null);

   const onHide = (): void => {
      if(setBookAppointmentOnSignIn) {
         setBookAppointmentOnSignIn(false); // Clear this flag if the Sign In modal is manually closed (SCREDP-12)
      }
      
      onClose();
   }

   const onClose = (): void => {
      setModalShow(false);
   };

   const onOpenModal = (): void => {
      setPageName(pageNameSent);
      setModalSize(undefined);
   };

   useEffect(() => {
      switch (pageName) {
         case ModalPageNames.INITIAL:
            setModalSize(undefined);
            break;
         case ModalPageNames.NEW_ACCOUNT:
            setModalSize("lg");
            break;
         case ModalPageNames.REQUEST_APPOINTMENT:
            setModalSize("lg");
            break;
         default:
            setModalSize(undefined);
      }

      if (pageName === ModalPageNames.SIGN_IN) {
         setIsPasswordSection(false);
         // AEP-64: Check for a previous rememberMe value in localStorage
         const storedRememberMe = localStorage.getItem('rememberMe');
         if (storedRememberMe) {
            setRememberMe(storedRememberMe === 'true');
         }
      }
   }, [pageName]);

   // AEP-64: Added use effect for rememberMe and email
   useEffect(() => {
      // Store the rememberMe value in localStorage
      localStorage.setItem('rememberMe', rememberMe.toString());
   }, [rememberMe]);

   useEffect(() => {
      if (rememberedEmail) {
         localStorage.setItem('email', rememberedEmail);
      } else {
         localStorage.removeItem('email');
      }
   }, [rememberedEmail]);

   useEffect(() => {
      if (emailInputRef.current && rememberedEmail) {
         emailInputRef.current.value = rememberedEmail;
      }
   }, [rememberedEmail]);

   return (
      <AuthenticationModal
         size={modalSize}
         show={show}
         onHide={onHide}
         onEnter={onOpenModal}
         aria-labelledby="contained-modal-title-vcenter"
         backdrop="static"
         keyboard={false}
         fullscreen={isMobile}
         className={isMobile ? "mobile-css" : "desktop-css"}
         centered
      >
         <div className="top-modal-border"></div>
         {pageName === ModalPageNames.INITIAL && (
            <SignInOrNewAppointment setPageName={setPageName} />
         )}
         {pageName === ModalPageNames.SIGN_IN && (
            <SignInComponent
               setPageName={setPageName}
               onClose={onClose}
               setEmailToBeVerified={setEmailToBeVerified}
               // AEP-64: Include the new props to the sing in component
               rememberMe={rememberMe}
               setRememberMe={setRememberMe}
               setRememberedEmail={setRememberedEmail}
               // Pass in password reset properties (SCREDP-55)
               setPasswordResetRequired={setPasswordResetRequired}
               emailInputRef={emailInputRef}
            />
         )}
         {pageName === ModalPageNames.REQUEST_APPOINTMENT && (
            <RequestAppointment
               onClose={onClose}
               confirmedAptDetails={confirmedAptDetails!}
            />
         )}
         {pageName === ModalPageNames.FORGOT_PASSWORD && (
            <ForgotPassword
               onClose={onClose}
               setEmailToBeVerified={setEmailToBeVerified}
               setPageName={setPageName}
               setIsPasswordSection={setIsPasswordSection}
               // Pass in password reset properties (SCREDP-55)
               passwordResetRequired={passwordResetRequired}
               passwordResetEmail={passwordResetRequired && emailInputRef.current?.value ? emailInputRef.current.value : ""}
            />
         )}
         {pageName === ModalPageNames.NEW_ACCOUNT && (
            <NewAccount
               setPageName={setPageName}
               onClose={onClose}
               setEmailToBeVerified={setEmailToBeVerified}
               setIsPasswordSection={setIsPasswordSection}
            />
         )}
         {pageName === ModalPageNames.VERIFYING_ACCOUNT && (
            <VerificationComponent
               emailToBeVerified={emailToBeVerified}
               onClose={onClose}
               isPasswordSection={isPasswordSection}
               setPageName={setPageName}
            />
         )}
         {pageName === ModalPageNames.APPOINTMENT_CONFIRMATION && (
            <AppointmentConfirmationModal
               onClose={onClose}
               confirmedAptDetails={confirmedAptDetails}
               setIsCancelConfirmed={setIsCancelConfirmed}
            />
         )}
         {pageName === ModalPageNames.CHANGE_PASSWORD && (
            <ChangePasswordModal 
               onClose={onClose} />
         )}
      </AuthenticationModal>
   );
};

export default SignInModal;