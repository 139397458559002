import React, { useContext, useState, useEffect  } from "react";
import {
   AppointmentCardHeaderDiv,
   Body,
   Header,
   StyledCalendarSolid,
   StyledConfirmAppointmentCard,
   StyledDrop,
   StyledGeoLocation,
   Title,
} from "./styled";
import Button from "react-bootstrap/Button";
import { Col, Row } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import {
   AppointmentTypesDropdownLabels,
   ModalPageNames,
} from "../../../../consts";
import SignInModal from "../../../../components/AuthenticationModals/SignInModal";
import ErrorModal from "../../../../components/ErrorModal";
import { AuthContext } from "../../../../contexts/auth/authContext";
import { AuthStatus } from "../../../../../services/cognitoService/AuthStatus";
import { getAppointmentDataServiceInstance } from "../../../../../services/bexWISE/appointmentDataService/getAppointmentDataServiceInstance";
import { formatDateValue } from "../../hooks/useAppointmentData";
import { NavigateFunction, useNavigate } from "react-router-dom";
import {
   IAppointmentDataService,
   IBookAppointmentRequest,
   IRescheduleAppointmentRequest,
} from "../../../../../services/bexWISE/appointmentDataService/IAppointmentDataService";
import { IConfirmedAppointmentDetails } from "../../../../../types/IConfirmedApponitmentDetails";
import { AppointmentKinds } from "../../../../consts/AppointmentKinds";
import { ROUTE_PATHS } from "../../../../consts/RoutePaths";
import { UserContext } from "../../../../contexts/user/userContext";
import { useSearchParams } from "react-router-dom";

function ConfirmAppointmentSection({
   card,
   appointmentTypeDisplayed,
   appointmentType,
   timeSelected,
   selectedDate,
   drive_id,
   timeSlotSelectedId,
   isNotEligible,
   eligibleDateForSelectedType,
   reschedulingAppointmentId,
   errorDivRef
}) {
   const [showSignInModal, setShowSignInModal] = useState<boolean>(false);
   const [pageNameSent, setPageNameSent] = useState<string>("");
   const [showAppointmentSlotErrorModal, setShowAppointmentSlotErrorModal] = useState<boolean>(false);
   const { authStatus } = useContext(AuthContext);
   const { profileData, userDonorId } = useContext(UserContext);
   const navigate: NavigateFunction = useNavigate();

   // Define variables to determine whether the selected appointment should be automatically booked upon signing in (SCREDP-12)
   const [bookAppointmentOnSignIn, setBookAppointmentOnSignIn] = useState<boolean>(false);

   const [confirmedAptDetails, setConfirmedAptDetails] = useState<IConfirmedAppointmentDetails>({
      addressOne: card?.location?.addressOne,
      name: card?.location?.name,
      timeSelected: timeSelected,
      dateSelected: selectedDate,
      city: card?.location?.city,
      state: card?.location?.state,
      zipCode: card?.location?.zipCode,
      appointmentKind: (isNotEligible ? AppointmentKinds.NOT_ELIGIBLE : (authStatus === AuthStatus.SignedOut ? AppointmentKinds.REQUESTED : AppointmentKinds.CONFIRMED)),
      userFirstName: profileData?.firstName || null,
      driveId: drive_id,
      driveDate: selectedDate,
      appointmentType: appointmentType,
      appointmentTime: timeSelected,
      appointmentTypeDisplayed: appointmentTypeDisplayed,
      eligibleDateForSelectedType: eligibleDateForSelectedType,
      appointmentSlotId: timeSlotSelectedId
   });

   const confirmAppointmentFunction = (): void => {
      if(authStatus === AuthStatus.SignedOut) {
         // If the user is not signed in, indicate that the selected appointment should automatically be booked upon signing in (SCREDP-12)
         setBookAppointmentOnSignIn(true);
      }

      setShowSignInModal(true);

      setPageNameSent(
         authStatus === AuthStatus.SignedOut
            ? ModalPageNames.INITIAL
            : ModalPageNames.APPOINTMENT_CONFIRMATION,
      );
   };

   const rescheduleAppointment = async (
      rescheduleAppointmentRequest: IRescheduleAppointmentRequest,
      reschedulingAppointmentId: number,
   ): Promise<void> => {
      try {
         const appointmentDataService: IAppointmentDataService =
            getAppointmentDataServiceInstance();
         await appointmentDataService.rescheduleAppointment(
            rescheduleAppointmentRequest,
            reschedulingAppointmentId,
         );
         sessionStorage.setItem(
            "confirmedAppointmentDetails",
            JSON.stringify(confirmedAptDetails),
         );
         navigate(`${ROUTE_PATHS.LANDING_PATH}`);
      } catch (err) {
         sessionStorage.removeItem("confirmedAppointmentDetails");
         throw err;
      }
   };

   const bookAppointment = async (
      bookAppointmentRequest: IBookAppointmentRequest,
   ): Promise<void> => {
      try {
         const appointmentDataService: IAppointmentDataService = getAppointmentDataServiceInstance();

         await appointmentDataService.bookAppointment(bookAppointmentRequest);

         sessionStorage.setItem(
            "confirmedAppointmentDetails",
            JSON.stringify(confirmedAptDetails),
         );

         navigate(`${ROUTE_PATHS.LANDING_PATH}`);
      } catch (err) {
         setShowAppointmentSlotErrorModal(true); // SCREDP-29: display error when booking appointment that has already been booked
         sessionStorage.removeItem("confirmedAppointmentDetails");
      }
   };

   {/* SCREDP-76 Rescheduling appointment does not update the existing appointment when location or date is changed */}
   const [searchParams] = useSearchParams();
   const continueButtonFunction = (): void => {
      if (authStatus === AuthStatus.SignedOut) {
         confirmAppointmentFunction();
      } else {
         continueBookOrRescheduleAppointment();
      }
   };

   const continueBookOrRescheduleAppointment = (): void => {
      if (userDonorId) {
         const bookOrReSchAptRequest: IBookAppointmentRequest = {
            donorId: userDonorId,
            driveId: drive_id,
            driveDate: formatDateValue(selectedDate),
            appointmentSlotId: timeSlotSelectedId,
         };

         {/* SCREDP-76 Rescheduling appointment does not update the existing appointment when location or date is changed */}
         if(!reschedulingAppointmentId){
            reschedulingAppointmentId = searchParams.get("reschedulingAppointmentId") || "";
         }

         if (reschedulingAppointmentId) {
            rescheduleAppointment(
               bookOrReSchAptRequest,
               Number(reschedulingAppointmentId),
            );
         } else {
            bookAppointment(bookOrReSchAptRequest);
         }
      }
   };

   // Update data in confirmedAptDetails when the user signs in (SCREDP-12)
   useEffect(() => {
      if(authStatus === AuthStatus.SignedIn) {
         setConfirmedAptDetails({
            ...confirmedAptDetails, 
            userFirstName: profileData?.firstName || null,
            eligibleDateForSelectedType: eligibleDateForSelectedType,
            appointmentKind: AppointmentKinds.CONFIRMED
         });
      }
   }, [authStatus, profileData, isNotEligible]);

   // Automatically continue booking the selected appointment upon signing in (SCREDP-12)
   useEffect(() => {
      if (bookAppointmentOnSignIn && authStatus == AuthStatus.SignedIn && profileData?.donorId) {
         if(isNotEligible) {
            errorDivRef.current?.scrollIntoView({ behavior: "smooth" });  // Scroll to the error message stating that the donor is not eligible
         }
         else {
            setBookAppointmentOnSignIn(false);
            continueBookOrRescheduleAppointment();
         }
      }
   }, [confirmedAptDetails]);

   const isMobile: boolean = useMediaQuery({ maxWidth: 767 });
   const isAccountNameSameAsLocationName = card?.location?.name === card?.account?.accountName;
   const errorMessage = "Sorry, your appointment could not be immediately confirmed. The appointment may no longer be available. " +
      "Please try again shortly, or consider a different time slot.\n\n" +
      "If this issue persists, please contact us, and we will look into it.";

   return (
      <>
         {(authStatus === AuthStatus.SignedOut || authStatus === AuthStatus.SignedIn && !isNotEligible) && 
            <div className="mb-3 mt-4">
               <Title className="mb-3">Confirm Selected Appointment:</Title>

               <StyledConfirmAppointmentCard>
                  <Header>
                     <AppointmentCardHeaderDiv>
                        <StyledGeoLocation />
                        <Title>
                           {card?.account?.accountName || ""}
                        </Title>
                     </AppointmentCardHeaderDiv>
                  </Header>
                  <Body as={Row}>
                     <Col
                        xs={12}
                        sm={12}
                        md={4}
                        className={isMobile ? "location-mobile-css" : ""}
                     >
                        {/* If the account name and the location are the same, only show the account name (SCREDP-63) */}
                        {!isAccountNameSameAsLocationName && (<>
                           {card?.location?.name || ""}<br />
                        </>)}
                        {card?.location?.addressOne || ""}
                        {card?.location?.addressTwo && <>
                           ,<br /> ${card?.location?.addressTwo}
                        </>
                        }
                        <br />
                        {card?.location?.city || ""}, {card?.location?.state || ""}{" "}
                        {card?.location?.zipCode || ""}
                     </Col>
                     <Col xs={12} sm={12} md={6}>
                        <div className="align-items-center-css">
                           <StyledCalendarSolid />
                           <b>When:</b>
                           <span className="margin-left-css">
                              {new Date(selectedDate).toLocaleDateString("en-US")} @{" "}
                              {new Date(
                                 timeSelected.replace("Z", ""),
                              ).toLocaleTimeString("en-US", {
                                 hour: "2-digit",
                                 minute: "2-digit",
                              })}
                           </span>
                        </div>
                        <div className="mb-2 align-items-center-css">
                           <StyledDrop />
                           <b>Donation:</b>
                           <span className="margin-left-css">
                              {AppointmentTypesDropdownLabels[appointmentType] ||
                                 appointmentTypeDisplayed}
                           </span>
                        </div>
                     </Col>
                     <Col xs={12} sm={12} md={2} className="continue-btn-div">
                        <Button
                           onClick={() => continueButtonFunction()}
                           variant="danger"
                           className="continue-confirm-btn"
                        >
                           Continue
                        </Button>
                     </Col>
                  </Body>
               </StyledConfirmAppointmentCard>
            </div>
         }
         
         <SignInModal
            show={showSignInModal}
            setModalShow={setShowSignInModal}
            pageNameSent={pageNameSent}
            confirmedAptDetails={confirmedAptDetails}
            setBookAppointmentOnSignIn={setBookAppointmentOnSignIn}
         />

         <ErrorModal /* SCREDP-29: display error when booking appointment that has already been booked */
            show={showAppointmentSlotErrorModal}
            setModalShow={setShowAppointmentSlotErrorModal}
            message={errorMessage}
         />
      </>);
}

export default ConfirmAppointmentSection;