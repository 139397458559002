import { AbstractBexWISEService } from "../bexWISEResolutionService/AbstractBexWISEService";
import {
   IDonorAppointmentDTO,
   IDonorContactPreference,
   IDonorDataService,
   IDonorDonationDTO,
   IDonorEligibilityDate,
   IDonorQRCodeInterface,
   IDonorValidationRequest,
} from "./IDonorDataService";
import { IDonorDTO, IDonorSex } from "../../../types/IDonorDTO";

export abstract class AbstractDonorDataService
   extends AbstractBexWISEService
   implements IDonorDataService
{
   public abstract getDonor(donorId: string): Promise<IDonorDTO>;

   public abstract getDonorAppointment(
      donorId: string,
      appointmentId: string,
   ): Promise<IDonorAppointmentDTO>;

   public abstract getDonorAppointments(
      donorId: string,
   ): Promise<IDonorAppointmentDTO[]>;

   public abstract getDonorContactPreferences(
      donorId: string,
   ): Promise<IDonorContactPreference[]>;

   public abstract setDonorContactPreferences(
      donorId: string,
      preferences: IDonorContactPreference[],
   ): Promise<void>;

   public abstract getDonorDonations(
      donorId: string,
   ): Promise<IDonorDonationDTO[]>;

   public abstract getDonorEligibilityDates(
      donorId: string,
   ): Promise<IDonorEligibilityDate[]>;

   public abstract getDonorIdCard(
      donorId,
      qrcodeParameters: IDonorQRCodeInterface,
   ): Promise<Blob>;

   public abstract validateDonor(
      validationRequest: IDonorValidationRequest,
   ): Promise<boolean>;

   public abstract getSexOptions(): Promise<IDonorSex[]>;
}
