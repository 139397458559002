import React, { ReactNode, ReactElement } from "react";
import { ISectionHeaderProps, SectionHeader } from "./SectionHeader";
import "./style.scss";
export interface ISectionProps {
   children?: ReactNode[] | ReactNode;
   className?: string;
   header?: ISectionHeaderProps;
   // == SCREDP-95 Allow Admin Customization of Text Displayed on Various Donor Portal Pages ==
   icon?: ReactElement;
}

export function Section(props: ISectionProps): ReactElement {
   // == SCREDP-95 Allow Admin Customization of Text Displayed on Various Donor Portal Pages ==
   const sectionHeaderProps : ISectionHeaderProps = {
      title: props.header?.title,
      icon: props.icon
   }
   return (
      <div className={`section ${props.className ? props.className : ""}`}>
         {/* SCREDP-95 Allow Admin Customization of Text Displayed on Various Donor Portal Pages */}
         {props.header && <SectionHeader {...sectionHeaderProps}/> }
         <div className={"section-body"}>{props.children}</div>
      </div>
   );
}
