import { useContext, useState } from "react";
import { CommonContext } from "../../../contexts/common/commonContext";
import {
   LocationOption,
   LocationOrAccountOptions,
   SortBy,
} from "../../../consts";
import { DateKind } from "../../Form/Inputs/Generic/FormDatePicker/DatePicker/DateKind";
import { getEndOfDay, getStartOfDay } from "../../../../utils/dateUtil";
import { EnvironmentContext } from "app/contexts/environment/environmentContext";

export interface IOpportunitySearchFilter {
   searchType: LocationOrAccountOptions;
   accountName: string;
   searchOrigin: string;
   radiusMiles: string;
   startDate: Date;
   endDate: Date;
   locationType: LocationOption;
   selectedAppointmentTypes: string[] | null;
   sortBy: SortBy;
   dateKind: DateKind;
}

export interface IUseOpportunitySearchFilter {
   searchFilters: IOpportunitySearchFilter;
   updateFilter: (update: Partial<IOpportunitySearchFilter>) => void;
}

export const defaultOpportunitySearchFilter: IOpportunitySearchFilter = {

   searchType: LocationOrAccountOptions.LOCATION,
   searchOrigin: "",
   accountName: "",
   radiusMiles: "10",
   startDate: getStartOfDay(),
   endDate: getEndOfDay(),
   locationType: LocationOption.ALL,
   selectedAppointmentTypes: [],
   sortBy: SortBy.CHRONOLOGICAL_ORDER,
   dateKind: DateKind.For_Today,
};

export function useOpportunitySearchFilter(): IUseOpportunitySearchFilter {
   const { defaultSearchOrigin, currentOriginFromBrowser } = //SCREDP-87 Include all of Zip and Allow Street Address as Default
      useContext(CommonContext);
   const origin = currentOriginFromBrowser ? currentOriginFromBrowser : defaultSearchOrigin; //SCREDP-87 Include all of Zip and Allow Street Address as Default
   const { environmentConfig } = useContext(EnvironmentContext);
   const defaultRadius = (environmentConfig?.defaultRadius ? environmentConfig?.defaultRadius + "" : "10");
   const [searchFilters, setSearchFilters] = useState<IOpportunitySearchFilter>(
      {
         ...defaultOpportunitySearchFilter,
         searchOrigin: `${origin}`,
         radiusMiles: defaultRadius, // set radiusMiles with the value defined in the admin portal (SCREDP-92)
         startDate: getStartOfDay(),
         endDate: getEndOfDay(),
         sortBy: SortBy.CHRONOLOGICAL_ORDER,
         locationType: LocationOption.ALL,
         selectedAppointmentTypes: [],
         dateKind: DateKind.For_Today,
      },
   );
   const updateFilter = (update: Partial<IOpportunitySearchFilter>) => {
      setSearchFilters({ ...searchFilters, ...update });
   };
   return {
      searchFilters,
      updateFilter,
   };
}
