import React, { useContext, useEffect, useState, useRef } from "react";
import {
   LocationOption,
   LocationOrAccountOptions,
   SortBy, NavigatorStatus
} from "app/consts";
import { AimIcon, FormInput, SearchIcon, CrossIcon } from "../../Searchbar/styled";
import { CommonContext } from "app/contexts/common/commonContext";
import {
   IOpportunitySearchContext,
   OpportunitySearchContext,
} from "../../../context/OpportunitySearchContext";
import { DateKind } from "app/components/Form/Inputs/Generic/FormDatePicker/DatePicker/DateKind";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { OpportunityLocatorViewContext } from "app/components/OpportunitySearchComponent/context/OpportunityLocatorViewContext";
import { OpportunityLocatorViews } from "app/consts";

export interface IOpportunitySearchFilter {
   searchType: LocationOrAccountOptions;
   accountName: string;
   searchOrigin: string;
   radiusMiles: string;
   startDate: Date;
   endDate: Date;
   locationType: LocationOption;
   selectedAppointmentTypes: string[] | null;
   sortBy: SortBy;
   dateKind: DateKind;
}

interface SearchbarProps {
   name: string;
   styles?: React.CSSProperties;
}

export const Searchbar: React.FC<SearchbarProps> = ({ name, styles = {} }) => {
   const { filters, updateFilter } = useContext<IOpportunitySearchContext>(
      OpportunitySearchContext,
   );

   // Get initial search values when page loads (SCREDP-44)
   const [searchBarValue, setSearchBarValue] = useState<string>(
      filters?.searchType === LocationOrAccountOptions.LOCATION
         ? filters.searchOrigin
         : filters.accountName,
   );

   const { currentOriginFromBrowser, defaultSearchOrigin, navigationStatus } =  //SCREDP-87 Include all of Zip and Allow Street Address as Default
      useContext(CommonContext);

   const inputRef = useRef<HTMLInputElement>(null); // Create a ref for the input field (AEP-61)

   const { selectedView, changeLayout } = React.useContext(
      OpportunityLocatorViewContext,
   );

   useEffect(() => {
      // Only get the zip code once (SCREDP-44)
      if ((searchBarValue === "" || searchBarValue === "undefined") && (filters.searchOrigin === "undefined")) {
         setCurrentZipCode();
      }
   }, [currentOriginFromBrowser, defaultSearchOrigin, navigationStatus]);  //SCREDP-87 Include all of Zip and Allow Street Address as Default

   useEffect(() => {
      // Update the searchBarValue when the filter changed and keep the focus on the input (SCREDP-86)
      const origin = navigationStatus === NavigatorStatus.DENIED ? defaultSearchOrigin : currentOriginFromBrowser;  //SCREDP-87 Include all of Zip and Allow Street Address as Default
      setSearchBarValue(
         filters?.searchType === LocationOrAccountOptions.LOCATION
            ? (origin === filters.searchOrigin ? "" : filters.searchOrigin)
            : filters.accountName
      );
      // After filters have been updated, focus the input field (AEP-61)
      if (inputRef.current) {
         inputRef.current.focus();
      }
   }, [filters]);

   const isShowAimIcon: boolean =
      filters?.searchType === LocationOrAccountOptions.LOCATION;
   const placeholder: string = isShowAimIcon
      ? "Search by zip code, city and state, or county" // Changed the placeholder to "city AND state" since both must be specified when searching for those due to behavior of the Google Maps Geocoding API. (SCREDP-44)
      : "Search account name ";

   const handleOnBlur = () => {
      // Update the filters on blur event (SCREDP-86)
      if (filters?.searchType === LocationOrAccountOptions.LOCATION) {
         const origin = navigationStatus === NavigatorStatus.DENIED ? defaultSearchOrigin : currentOriginFromBrowser;  //SCREDP-87 Include all of Zip and Allow Street Address as Default
         const value = (searchBarValue === "") ? origin : searchBarValue;
         updateFilter({
            searchOrigin: `${value}`,
         });
      } else {
         updateFilter({
            accountName: searchBarValue,
         });
      }
   };

   const setCurrentZipCode = () => {
      setSearchBarValue("");
      const origin = navigationStatus === NavigatorStatus.DENIED ? defaultSearchOrigin : currentOriginFromBrowser;  //SCREDP-87 Include all of Zip and Allow Street Address as Default
      updateFilter({
         searchOrigin: `${origin}`,
      });
   };

   const onChange = (newValue: string) => {
      setSearchBarValue(newValue);
   };

   const handleSearch = (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault(); // Prevent form submission
      // Update the filters on search event (SCREDP-86)
      if (filters?.searchType === LocationOrAccountOptions.LOCATION) {
         updateFilter({
            searchOrigin: searchBarValue
         });
      } else {
         updateFilter({
            accountName: searchBarValue
         });
      }
      // If we are in the landing page, navigate to the full map layout page (SCREDP-86)
      if (selectedView === OpportunityLocatorViews.LANDING_LAYOUT) {
         changeLayout(OpportunityLocatorViews.FULL_MAP_CONTRACTED_LAYOUT);
      }
   };

   return (
      <div className="position-relative">
         {/* if the input hava a value, we show a cross icon to clean the input text, 
             otherwise we show the search icon. (SCREDP-44) */}
         {searchBarValue ? (
            <CrossIcon fill="grey" onClick={() => {
               // When clicking on the cross icon, we clean the search value and the filter values (SCREDP-44)
               setSearchBarValue("");
               if (filters?.searchType === LocationOrAccountOptions.LOCATION) {
                  const origin = navigationStatus === NavigatorStatus.DENIED ? defaultSearchOrigin : currentOriginFromBrowser;  //SCREDP-87 Include all of Zip and Allow Street Address as Default
                  updateFilter({
                     searchOrigin: `${origin}`,
                  });
               } else {
                  updateFilter({
                     accountName: "",
                  });
               }
               inputRef.current?.focus(); // Set focus on the input element
            }} />
         ) : <SearchIcon fill="grey" />}

         <FormInput
            ref={inputRef} // Set the input field reference
            type="text"
            name={name}
            placeholder={placeholder || ""}
            value={(searchBarValue === "undefined") ? "" : searchBarValue}
            styles={{ ...styles }}
            onChange={change => onChange(change.target.value)}
            onBlur={handleOnBlur}
            // added onkeydown to execute the search on enter key. (SCREDP-86)
            onKeyDown={e => {
               if (e.keyCode === 13) {// Check if the Enter key was pressed
                  handleSearch(e);
               }
            }}
         />

         {/*Add a tooltip to indicate the use of the user's current location. (SCREDP-44) */}
         {isShowAimIcon && navigationStatus !== NavigatorStatus.DENIED && <OverlayTrigger
            placement="left"
            overlay={<Tooltip>Use my location</Tooltip>}
         ><AimIcon onClick={() => setCurrentZipCode()} /></OverlayTrigger>}
      </div>
   );
};

export default Searchbar;
