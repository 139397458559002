import React, { useContext, useRef } from "react";
import { StyledCard } from "./styled";
import { v4 as uuidv4 } from "uuid";
import { IBloodDrive } from "types/IBloodDrive";
import CardBody from "./CardBody";
import CardFooter from "./CardFooter";
import CardHeader from "./CardHeader";
import { useNavigate } from "react-router-dom";
import {
   IOpportunitySearchContext,
   OpportunitySearchContext,
} from "app/components/OpportunitySearchComponent/context/OpportunitySearchContext";
import { ROUTE_PATHS } from "../../../../consts/RoutePaths";
import { Col } from "react-bootstrap";
import { LocationOrAccountOptions } from "app/consts";
import { useSearchParams } from "react-router-dom";

export interface OpportunityCardProps {
   details: IBloodDrive;
}

const OpportunityCard: React.FC<OpportunityCardProps> = ({ details }) => {
   const { selectedDriveId, filters } = useContext<IOpportunitySearchContext>(
      OpportunitySearchContext,
   );

   const ref = useRef<HTMLDivElement>(null);
   const navigate = useNavigate();
   {/* SCREDP-76 Rescheduling appointment does not update the existing appointment when location or date is changed */}
   const [searchParams] = useSearchParams();
   const handleNavigate = (): void => {
      const reschedulingAppointmentId = searchParams.get("reschedulingAppointmentId") || "";
      const reschedulingAppointmentIdUrlParam = reschedulingAppointmentId? "&reschedulingAppointmentId="+reschedulingAppointmentId : "";
      // Include the origin to the url only if the search type is location. (SCREDP-77)
      const origin = filters.searchType === LocationOrAccountOptions.LOCATION ? `&origin=${filters.searchOrigin}` : "";
      navigate(`${ROUTE_PATHS.APPOINTMENT_LIST}?drive_id=${details.driveId}&date=${details.driveDate}` + origin + reschedulingAppointmentIdUrlParam, { state: { details } });
   };

   const checkTotalAvailability = (): number => {
      let totalAvailability = 0;
      details.appointmentTypes?.forEach(t => {
         totalAvailability = Number(
            totalAvailability + t.appointmentsRemaining,
         );
      });
      return totalAvailability;
   };
   const count: number = checkTotalAvailability();

   return (
      <Col className={"opportunity-card"} ref={ref}>
         <StyledCard
            key={uuidv4().toString()}
            onClick={handleNavigate}
            id={details.resultId}
            $selectedDriveId={selectedDriveId}
            disabled={!count}
         >
            <CardHeader details={details} />
            {/* Include account and location names in the body (SCREDP-63) */}
            <CardBody details={details} />
            <CardFooter
               availability={details?.appointmentTypes || []}
               count={count}
            />
         </StyledCard>
      </Col>
   );
};

export default OpportunityCard;
