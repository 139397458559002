import HomePrefIcon from "../../../assets/images/home-preferences.png";
import CellPhonePrefIcon from "../../../assets/images/cell-phone-preferences.png";
import BusinessIcon from "../../../assets/images/business-preferences.png";
import UnsubscribeIcon from "../../../assets/images/unsubscribe-preferences.png";

export interface ISwitchInterface {
   name: string;
   contactPreferenceCode: string;
   contactPreferenceDescription: string;
}

export interface ISwitchesSectionsInterface {
   label: string;
   image: string;
   id: string;
   switches: ISwitchInterface[];
   // == SCREDP-95 Allow Admin Customization of Text Displayed on Various Donor Portal Pages ==
   message: string | undefined;
}
export const switchesSectionsContent: ISwitchesSectionsInterface[] = [
   {
      label: "Home",
      image: HomePrefIcon,
      id: "home",
      switches: [
         {
            name: "Email",
            contactPreferenceCode: "HE",
            contactPreferenceDescription: "Home EMail",
         },
         {
            name: "Phone",
            contactPreferenceCode: "HP",
            contactPreferenceDescription: "Home Phone",
         },
         {
            name: "Standard Mail",
            contactPreferenceCode: "SM",
            contactPreferenceDescription: "Standard Mail",
         },
      ],
      // == SCREDP-95 Allow Admin Customization of Text Displayed on Various Donor Portal Pages ==
      message: "Home preference",
   },
   {
      label: "Cell Phone",
      image: CellPhonePrefIcon,
      id: "cellPh",
      switches: [
         {
            name: "Text Message",
            contactPreferenceCode: "TM",
            contactPreferenceDescription: "Text Message",
         },
         {
            name: "Phone",
            contactPreferenceCode: "CP",
            contactPreferenceDescription: "Cell Phone",
         },
      ],
      // == SCREDP-95 Allow Admin Customization of Text Displayed on Various Donor Portal Pages ==
      message: "Cell preference",
   },
   {
      label: "Business",
      image: BusinessIcon,
      id: "business",
      switches: [
         {
            name: "Email",
            contactPreferenceCode: "BE",
            contactPreferenceDescription: "Business EMail",
         },
         {
            name: "Phone",
            contactPreferenceCode: "BP",
            contactPreferenceDescription: "Business Phone",
         },
      ],
      // == SCREDP-95 Allow Admin Customization of Text Displayed on Various Donor Portal Pages ==
      message: "Business preference",
   },
   {
      label: "Unsubscribe",
      image: UnsubscribeIcon,
      id: "unsubscribe",
      switches: [
         {
            name: "Email",
            contactPreferenceCode: "OO",
            contactPreferenceDescription: "Opt-Out of EMail",
         },
         {
            name: "Text Message",
            contactPreferenceCode: "OT",
            contactPreferenceDescription: "Opt-Out of Text",
         },
      ],
      // == SCREDP-95 Allow Admin Customization of Text Displayed on Various Donor Portal Pages ==
      message: "Unsubscribe",
   },
];
