import React from "react";
import { Header } from "./styled";

interface TooltipHeaderProps {
   title: string;
   icon?: React.ReactElement;
}

const TooltipHeader: React.FC<TooltipHeaderProps> = ({ title, icon }) => {
   return (
      <Header>
         <h4>{title}</h4>
         {icon}
      </Header>
   );
};

export default TooltipHeader;
