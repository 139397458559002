import React, { useEffect, useState } from "react";
import { PageComponent } from "../../components/PageComponent";
import { useSearchParams, useLocation } from "react-router-dom";
import {
   formatDateValue,
   useAppointmentData,
} from "./hooks/useAppointmentData";
import {
   AppointmentDataContext,
   IAppointmentDataContext,
} from "./context/AppointmentDataContext";
import {
   IDriveAppointmentAvailability,
   IDriveAppointmentAvailabilityTimes,
} from "../../../services/bexWISE/bloodDriveDataService/IBloodDriveDataService";
import { IUserContext, UserContext } from "../../contexts/user/userContext";
import { AuthContext, IAuthContext } from "../../contexts/auth/authContext";
import { getBloodDriveDataServiceInstance } from "../../../services/bexWISE/bloodDriveDataService/getBloodDriveDataServiceInstance";
import { AuthStatus } from "../../../services/cognitoService/AuthStatus";
import { useMediaQuery } from "react-responsive";
import {
   AppointmentListContainer,
   AppointmentListOuterContainer,
   ExclamationImg,
   StyledAppointmentCard,
} from "./styled";
import { Alert, Stack } from "react-bootstrap";
import AppointmentCard from "./components/AppointmentCard";
import AppointmentType from "./components/AppointmentType";
import AppointmentTimes from "./components/AppointmentTimesSection";
import ConfirmAppointmentCard from "./components/ConfirmAppointmentCard";
import AppointmentText from "./components/AppointmentText/AppointmentText";
import Button from "react-bootstrap/Button";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { default as dayjs } from "dayjs";
import Separator from "app/components/OpportunitySearchComponent/components/OpportunityCard/Separator";
import { EnvironmentContext } from "app/contexts/environment/environmentContext";

function formatDate(date: Date): string {
   const options: Intl.DateTimeFormatOptions = {
      month: "short",
      day: "numeric",
      year: "numeric",
   };
   return date.toLocaleDateString("en-US", options);
}

function getAppointmentTimesDisplayed(
   appointmentTimes: IDriveAppointmentAvailability[],
   appointmentType: string,
): IDriveAppointmentAvailabilityTimes[] {
   if (Array.isArray(appointmentTimes) && appointmentTimes.length) {
      const specificAptTimes: IDriveAppointmentAvailabilityTimes[] | undefined =
         appointmentTimes.find(
            each =>
               each.appointmentTypeSummary.appointmentType === appointmentType,
         )?.appointmentTimes;
      return specificAptTimes || [];
   } else {
      return [];
   }
}

export function AppointmentListPage() {
   // Get parameters from url since the "detailsRef" object may not be in memory if the Appointments page was loaded directly (SCREDP-77)
   const [searchParams] = useSearchParams();
   const drive_id: string = searchParams.get("drive_id") || "";
   const drive_date: string = searchParams.get("date") || "";
   const origin: string = searchParams.get("origin") || "";
   
   const location = useLocation();
   
   // Get the drive information from memory if it exists. Note that it will not if this page is linked to directly
   const detailsRef = (location.state as any)?.details;
   
   const {
      appointmentData,
      appointmentTypes,
      appointmentType,
      setAppointmentType,
      eligibilityDates,
   } = useAppointmentData(drive_id, drive_date, origin, detailsRef);
   
   // Get the location type from appointmentData instead of detailsRef since the latter won't be populated if this page is linked to directly (SCREDP-100)
   const selectedLocationType = appointmentData?.location.locationType.code;
   
   const [selectedDate, setSelectedDate] = React.useState<Date | "">("");
   const [context, setContext] = useState<IAppointmentDataContext>({});
   const [appointmentTimes, setAppointmentTimes] = useState<IDriveAppointmentAvailability[]>([]);
   const [appointmentTimesDisplayed, setAppointmentTimesDisplayed] = useState<IDriveAppointmentAvailabilityTimes[] | []>([]);
   const [eligibleDateForSelectedType, setEligibleDateForSelectedType] = useState<string | undefined>("");
   const [isNotEligible, setIsNotEligible] = useState<boolean>(false);
   const [timeSelected, setTimeSelected] = useState<string>("");
   const [timeSlotSelectedId, setTimeSlotSelectedId] = useState<number>(0);
   const [reschedulingAppointmentId, setReschedulingAppointmentId] = useState<string>("");
   const { profileData } = React.useContext<IUserContext>(UserContext);
   const { authStatus } = React.useContext<IAuthContext>(AuthContext);
   const isMobile: boolean = useMediaQuery({ maxWidth: 767 });
   const navigate: NavigateFunction = useNavigate();
   const { environmentConfig } = React.useContext(EnvironmentContext);
   // SCREDP-76 Rescheduling appointment does not update the existing appointment when location or date is changed 
   const reschedulingAppointmentIdUrl = reschedulingAppointmentId? "?reschedulingAppointmentId="+reschedulingAppointmentId : "";

  // Create a reference to the error div (SCREDP-12)
   const errorDivRef = React.useRef<HTMLDivElement>(null);

   useEffect(() => {
      const reSchAptId: string | null = sessionStorage.getItem(
         "ReschedulingAppointmentId",
      );

      if (reSchAptId) {
         setReschedulingAppointmentId(reSchAptId);
         sessionStorage.removeItem("ReschedulingAppointmentId");
      }

      // Set the drive date on load (SCREDP-35)
      const driveDate = dayjs(drive_date).toDate();
      setSelectedDate(driveDate);
   }, []);

   useEffect(() => {
      if (eligibilityDates?.length) {
         const eligibleDate: string | undefined = eligibilityDates.find(
            each => each?.appointmentType === appointmentType,
         )?.eligibleDate;
         setEligibleDateForSelectedType(eligibleDate);
      }
   }, [eligibilityDates, appointmentType]);

   useEffect(() => {
      if (authStatus === AuthStatus.SignedOut) {
         setIsNotEligible(false);
      } 
      else if (authStatus === AuthStatus.SignedIn)  {
         if (eligibleDateForSelectedType && selectedDate) {
            const eligibleDate: Date = new Date(eligibleDateForSelectedType);
            const selectDate: Date = new Date(selectedDate);
            if (eligibleDate.getTime() > selectDate.getTime()) {
               setIsNotEligible(true);
            } else {
               setIsNotEligible(false);
            }
         } else {
            setIsNotEligible(true);
         }
      }
   }, [eligibleDateForSelectedType, selectedDate, authStatus]);

   useEffect(() => {
      if (selectedDate) {
         getBloodDriveDataServiceInstance()
            .getDriveAppointments(drive_id, formatDateValue(selectedDate))
            .then((availability: IDriveAppointmentAvailability[]) => {
               setAppointmentTimes(availability);
            });
      }
      setTimeSelected("");
   }, [selectedDate]);

   useEffect(() => {
      setTimeSelected("");
      setAppointmentTimesDisplayed(
         getAppointmentTimesDisplayed(appointmentTimes, appointmentType),
      );
   }, [appointmentType, appointmentTimes]);

   const getAppointmentNameDescription = (
      appointmentType: string,
   ): string | undefined => {
      if (
         appointmentTypes &&
         Array.isArray(appointmentTypes) &&
         appointmentTypes.length > 0
      ) {
         return appointmentTypes.find(
            each => each.appointmentType === appointmentType,
         )?.appointmentTypeDescription;
      } else {
         return "";
      }
   };

   // If there is not message set in the admin portal, show a default message (SCREDP-94)
   const unavaliableMessage = () => {
      const defaultMessage = <p className="mb-5">Your dedication to saving lives is greatly appreciated! Unfortunately, we are no longer accepting <span className="bold">online</span> appointments for this event. Please use the "Contact Us" link if there are any additional questions we can answer or the "Change Location" button to look for another date or location.</p>;
      return (environmentConfig?.appointmentsUnavailableMessage ? environmentConfig?.appointmentsUnavailableMessage : defaultMessage);
   }

   // Check when to show or hide the appointments based on the admin settings (SCREDP-94)
   const showAppointmentTimes = () => {
      const isCenterDrive = selectedLocationType === "F";
      const appointmentsUnavailableThreshold = isCenterDrive
         ? environmentConfig?.centerAppointmentsUnavailableThreshold
         : environmentConfig?.mobileAppointmentsUnavailableThreshold;

      if (!appointmentsUnavailableThreshold) { // normal case, show all the appts for the drive
         return true;
      }

      const currentDate = new Date();
      const currentTime = currentDate.getTime();

      // Get the driveStartTime from appointmentData instead of detailsRef since the latter won't be populated if this page is linked to directly (SCREDP-100)
      const driveStartTime = (appointmentData?.startTime ? new Date(appointmentData.startTime).getTime() : null);

      let result = true;
      const thresholdType = isCenterDrive
         ? environmentConfig?.centerAppointmentsUnavailableThresholdType
         : environmentConfig?.mobileAppointmentsUnavailableThresholdType;

      if(driveStartTime) {
         switch (thresholdType) {
            case "days": // # of Days = should prevent appointments from being booked # of days prior of the drive date
               const days = Number(appointmentsUnavailableThreshold);
               const thresholdDate = new Date(driveStartTime);
               thresholdDate.setHours(0, 0, 0, 0);
               thresholdDate.setDate(thresholdDate.getDate() - days);
               currentDate.setHours(0, 0, 0, 0);
               if (thresholdDate <= currentDate) {
                  result = false;
               }
               break;
            case "hours": // # of Hours = should prevent appointments from being booked # of hours prior of the drive start time
               const thresholdTime = new Date(driveStartTime);
               thresholdTime.setHours(thresholdTime.getHours() - Number(appointmentsUnavailableThreshold));
               if (thresholdTime.getTime() <= currentTime) {
                  result = false;
               }
               break;
            default:
               break;
         }
      }

      return result;
   }

   return (
      <PageComponent
         name={"AppointmentDataPage"}
         title={"Schedule Appointment"}
         description={"Appointment Data Page"}
      >
         <AppointmentDataContext.Provider value={context}>
            <AppointmentListOuterContainer
               className={isMobile ? "mobile-version-css" : "medium-screen-css"}
            >
               <AppointmentListContainer
                  className={isMobile ? "mobile-version-css" : "medium-screen-css"}
               >
                  {/* Remove the calendar to only show one drive at a time (SCREDP-35)
                  <HorizontalCalendar
                     driveDate={drive_date}
                     setSelectedDate={setSelectedDate}
                  /> */}

                  <Stack gap={3} className={isMobile ? "stackContentMobile" : "stackContent"}>
                     {/* SCREDP-76 Rescheduling appointment does not update the existing appointment when location or date is changed */}
                     <AppointmentCard card={appointmentData}  reschedulingAppointmentId={ reschedulingAppointmentId} />

                     <div>
                        {/* Added the selected date to the top of the appointment section (SCREDP-94 */}
                        {selectedDate && <Separator startTime={selectedDate} />}

                        <StyledAppointmentCard className={
                           isMobile ? "mobile-version-css" : ""
                        }>
                           {/* Show or hide appointments text depending on the admin configuration (SCREDP-94) */}
                           {showAppointmentTimes() &&
                              <AppointmentText timesCount={appointmentTimes.length}
                                 isSingleAppointmentType={appointmentTypes.length === 1}
                              />}

                           {appointmentTimes.length > 0 && appointmentType ? (
                              <div className="bottom-section-css">
                                 {/* <AppointmentDate appointmentDate={selectedDate} /> */}

                                 {/* Show or hide appointments types depending on the admin configuration (SCREDP-94) */}
                                 {showAppointmentTimes() ?
                                    <>
                                       <AppointmentType
                                          appointmentTypes={appointmentTypes}
                                          appointmentType={appointmentType}
                                          setAppointmentType={setAppointmentType}
                                       />
                                    </>
                                    : unavaliableMessage() // if the appointments types are hidden, show the unavaliable message (SCREDP-94)
                                 }

                                 {authStatus == AuthStatus.SignedIn && profileData && isNotEligible && (
                                    <Alert ref={errorDivRef} variant="danger" className="alert-msg-css">
                                       <ExclamationImg />
                                       <div>
                                          <b>
                                             {profileData?.firstName || ""},
                                             unfortunately, you are not eligible to
                                             donate{" "}
                                             {getAppointmentNameDescription(
                                                appointmentType,
                                             )}
                                             {eligibleDateForSelectedType ? (
                                                <>
                                                   {" "}
                                                   until{" "}
                                                   {eligibleDateForSelectedType &&
                                                      formatDate(
                                                         new Date(
                                                            eligibleDateForSelectedType,
                                                         ),
                                                      )}
                                                   .
                                                </>
                                             ) : (
                                                <>.</>
                                             )}
                                          </b>{" "}
                                          <br />
                                          {isMobile && <br />}
                                          Please try a different appointment type,
                                          select a different day, or change your
                                          location.
                                       </div>
                                    </Alert>
                                 )}

                                 {/* Show or hide appointments times depending on the admin configuration (SCREDP-94) */}
                                 {showAppointmentTimes() && appointmentTimesDisplayed &&
                                    appointmentTimesDisplayed.length > 0 && (
                                       <AppointmentTimes
                                          appointmentTimesDisplayed={
                                             appointmentTimesDisplayed
                                          }
                                          timeSelected={timeSelected}
                                          setTimeSelected={setTimeSelected}
                                          isNotEligible={isNotEligible}
                                          setTimeSlotSelectedId={
                                             setTimeSlotSelectedId
                                          }
                                          card={appointmentData}
                                       />
                                 )}

                                 {timeSelected && (
                                    <ConfirmAppointmentCard
                                       card={appointmentData}
                                       appointmentTypeDisplayed={getAppointmentNameDescription(
                                          appointmentType,
                                       )}
                                       appointmentType={appointmentType}
                                       timeSelected={timeSelected}
                                       selectedDate={selectedDate}
                                       drive_id={drive_id}
                                       timeSlotSelectedId={timeSlotSelectedId}
                                       isNotEligible={isNotEligible}
                                       eligibleDateForSelectedType={
                                          eligibleDateForSelectedType &&
                                          formatDate(
                                             new Date(eligibleDateForSelectedType),
                                          )
                                       }
                                       reschedulingAppointmentId={
                                          reschedulingAppointmentId
                                       }
                                       errorDivRef={errorDivRef}
                                    />
                                 )}
                              </div>
                           ) : (
                              <>
                                 <div className="no-appointment-class">
                                    No appointments available for this date.
                                 </div>
                              </>
                           )}
                        </StyledAppointmentCard>
                     </div>
                  </Stack>
                  {/* SCREDP-76 Rescheduling appointment does not update the existing appointment when location or date is changed */}
                  {isMobile && (
                     <Button
                        className="arrow-section"
                        onClick={() => navigate("/"+reschedulingAppointmentIdUrl)}
                     >
                        <i className="pi pi-search"></i>
                     </Button>
                  )}

               </AppointmentListContainer>
            </AppointmentListOuterContainer>
         </AppointmentDataContext.Provider>
      </PageComponent>
   );
}