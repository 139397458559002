import React, { useContext, useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { Email, Password } from "../authComponents";
import Button from "react-bootstrap/Button";
import { Modal, Row } from "react-bootstrap";
import {
   useValidEmail,
   useValidPassword,
} from "../../../hooks/useAuthValidationHook";
import { AuthContext } from "../../../contexts/auth/authContext";
import { ModalPageNames } from "../../../consts";
import { ReactComponent as BoxArrowInRight } from "../../../../assets/svgs/box-arrow-in-right.svg";
import { CommonModalHeader } from "../styled";
import { SignInContainer, SignInModalAlert } from "./styled";
import { useMediaQuery } from "react-responsive";

const SignInComponent = ({ setPageName, onClose, setEmailToBeVerified, rememberMe, setRememberMe, setRememberedEmail, setPasswordResetRequired, emailInputRef }) => {
   const savedEmail = localStorage.getItem("email"); // AEP-64: Saved email to local storage
   const { email, setEmail } = useValidEmail(savedEmail ? savedEmail : "");
   const { password, setPassword } = useValidPassword("");
   const [passwordVisible, isPasswordVisible] = useState(false);
   const [error, setError] = useState("");

   const isInValid: boolean = email.length === 0 || password.length === 0;

   const authContext = useContext(AuthContext);

   const isMobile = useMediaQuery({ maxWidth: 767 });

   const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault(); // Prevent form submission
      signInClicked();
   };

   const signInClicked = async () => {
      try {
         // AEP-64: Persist the email if rememberMe is true
         if (rememberMe) {
            setRememberedEmail(email);
         } else {
            setRememberedEmail('');
         }

         await authContext.signInWithEmail(email, password);
         onClose();
      } catch (err: any) {
         if (err.error?.code === "UserNotConfirmedException") {
            resendCodeConfirmation();
         }
         // Handle a Passoword Reset Required error (SCREDP-55)
         else if(err.error?.code === "PasswordResetRequiredException") {
            passwordResetRequired();
         }
         else {
            setError(err.error?.message || err?.message);
         }
      }
   };

   const resendCodeConfirmation = async () => {
      try {
         await authContext.resendCode(email);
         setEmailToBeVerified(email);
         setPageName(ModalPageNames.VERIFYING_ACCOUNT);
      } catch (err: any) {
         console.error(err.message);
      }
   };

   // Display the Forgot Password modal when the user clicks on the "Forgot password" link (SCREDP-117)
   const forgotPassword = () => {
      setPageName(ModalPageNames.FORGOT_PASSWORD);
   }

   // Display the Forgot Password modal when a password reset is required (SCREDP-55)
   const passwordResetRequired = () => {
      setPasswordResetRequired(true);
      setPageName(ModalPageNames.FORGOT_PASSWORD);
   };

   // AEP-64: Set the remember me value
   const handleRememberMeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRememberMe(event.target.checked);
   };

   return (
      <>
         <Modal.Header closeButton></Modal.Header>
         <CommonModalHeader
            className={isMobile ? "mobile-header-css" : "desktop-header-css"}
         >
            <h5>
               <b>Sign In</b>
            </h5>
         </CommonModalHeader>
         {error && (
            <SignInModalAlert
               variant="danger"
               className={isMobile ? "mobile-alert-css" : "desktop-alert-css"}
            >
               {error}
            </SignInModalAlert>
         )}
         <Modal.Body>
            <SignInContainer
               className={isMobile ? "mobile-css" : "desktop-css"}
            >
               {/* Changed the way we submit the form for login to allow to submit on "Enter" button */}
               <Form onSubmit={handleSubmit} className={isMobile ? '' : 'mb-5'}>
                  <Form.Group >
                     <Email
                        emailIsValid
                        email={email}
                        setEmail={setEmail}
                        placeholderValue="Email"
                        isRequired={false}
                        inputRef={emailInputRef} // AEP-64: Pass the ref to the Email component
                        autofocus={true} // Set focus to the email input (SCREDP-55)
                     />
                     <Password
                        label="Password"
                        passwordIsValid
                        setPassword={setPassword}
                        setError={setError}
                        passwordVisible={passwordVisible}
                        isPasswordVisible={isPasswordVisible}
                        isRequired={false}
                        isPasswordShowRequired
                     />
                  </Form.Group>
                  <Form.Group className="forgot-password-group">
                     <Button
                        className="forgot-password-link"
                        variant="link"
                        onClick={forgotPassword} // Display the Forgot Password modal when the user clicks on the "Forgot password" link (SCREDP-117)
                     >
                        Forgot password?
                     </Button>
                  </Form.Group>
                  <Form.Group as={Row} className={`${isMobile ? "mb-3" : "mb-5"} remember-label-group`}
                     controlId="formKeepMeSignedInCheck"
                  >
                     <Form.Check
                        label="Remember me"
                        className="remember-label"
                        checked={rememberMe} // AEP-64: Bind the checkbox to the rememberMe state
                        onChange={handleRememberMeChange} // AEP-64: Add onChange event handler
                     />
                  </Form.Group>
                  <Form.Group>
                     <Button
                        type="submit" // Set button type to "submit"
                        disabled={isInValid}
                        className="sign-in-button"
                        variant="danger"
                     >
                        Sign In <BoxArrowInRight className="box-arrow-css" />
                     </Button>
                  </Form.Group>
               </Form>

               <Form.Group className="mb-3 create-account-group">
                  <span>Don't have an account?</span>
                  <Button
                     className="create-account-link"
                     variant="link"
                     onClick={() => setPageName(ModalPageNames.NEW_ACCOUNT)}
                  >
                     <b>Create an account</b>
                  </Button>
               </Form.Group>
            </SignInContainer>
         </Modal.Body>
      </>
   );
};

export default SignInComponent;