import styled from "styled-components";
import Form from "react-bootstrap/Form";
import { PRIMARY_COLORS } from "../../../consts/colors";

export const RequestAptForm = styled(Form)`
   .sex-desktop-section {
      display: grid;
      align-items: center;
      grid-template-columns: auto auto;
      .header {
         color: ${PRIMARY_COLORS.FIRE_ENGINE_RED};
         font-weight: 600;
         margin-bottom: 0px;
      }
   }
   .footer-section-css {
      display: grid;
      grid-template-columns: auto auto;
      .btn-css {
         font-size: 12px;
         background-color: ${PRIMARY_COLORS.FIRE_ENGINE_RED};
         border-color: ${PRIMARY_COLORS.FIRE_ENGINE_RED};
      }
   }
   .mobile-footer-css {
      padding-left: 10px;
      grid-gap: 5%;
   }
   .desktop-footer-css {
      width: 50%;
      float: right;
      padding-left: 30px;
      grid-gap: 30%;
   }
`;
