import React from "react";
import { ClipBoardIcon, LocationSectionDiv } from "./styled";
import { IDonorDonationDTO } from "../../../../../../services/bexWISE/donorDataService/IDonorDataService";
import { displayDate } from "../../../utils/utils";
// == SCREDP-95 Allow Admin Customization of Text Displayed on Various Donor Portal Pages ==
import { RiInformationFill } from "react-icons/ri";
import { isStringNullUndefinedOrEmpty } from "app/pages/MyDonationPage/utils/utils";
import { EnvironmentContext } from "app/contexts/environment/environmentContext";
import { Tooltip } from "primereact/tooltip";

interface LocationSectionProps {
   lastDonorData: IDonorDonationDTO;
}
const LocationSection: React.FC<LocationSectionProps> = ({ lastDonorData }) => {
   // == SCREDP-95 Allow Admin Customization of Text Displayed on Various Donor Portal Pages ==
   const { environmentConfig } = React.useContext(EnvironmentContext);
   return (
      <LocationSectionDiv>
         <ClipBoardIcon />
         <div className="right-section">
            <div className="header-css">
               {/* SCREDP-95 Allow Admin Customization of Text Displayed on Various Donor Portal Pages */}
               Last Physical Exam
               <Tooltip target="#lastPhysicalExamRiInformationFill" />
               <RiInformationFill 
                  id="lastPhysicalExamRiInformationFill"
                  data-pr-tooltip={
                     isStringNullUndefinedOrEmpty(environmentConfig?.lastPhysicalExamMessage)
                        ?"Last Physical Exam"
                        :environmentConfig?.lastPhysicalExamMessage
                  } 
                  data-pr-position="bottom"
                  fill="black" 
                  size={20} 
               />
            </div>
            <div className="content-css">
               <div>
                  Date Collected: <b>{displayDate(lastDonorData.date, true)}</b>
               </div>
               <div>Location: {lastDonorData.location?.name}</div>
               <div>
                  {lastDonorData.location?.addressOne},
                  {lastDonorData.location?.city},{lastDonorData.location?.state}{" "}
                  {lastDonorData.location?.zipCode}
               </div>
            </div>
         </div>
      </LocationSectionDiv>
   );
};

export default LocationSection;
