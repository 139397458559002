import React, { ReactElement, useContext } from "react";
import { Section } from "../../../../components/Section/Section";
// == SCREDP-95 Allow Admin Customization of Text Displayed on Various Donor Portal Pages ==
import { RiInformationFill } from "react-icons/ri";
import MyDonationSlots from "../MyDonationSlots";
import {
   DonationContext,
   IDonationContext,
} from "../../context/DonationContext";
import DonationNotFound from "../DonationNotFound";
import { CommonContext } from "../../../../contexts/common/commonContext";
import { MyDonationsFilters } from "../MyDonationsFilters/MyDonationsFilters";
// == SCREDP-95 Allow Admin Customization of Text Displayed on Various Donor Portal Pages ==
import { EnvironmentContext } from "app/contexts/environment/environmentContext";
import { isStringNullUndefinedOrEmpty } from "../../utils/utils";
import { Tooltip } from 'primereact/tooltip';

export function MyDonationsBody(): ReactElement {
   // == SCREDP-95 Allow Admin Customization of Text Displayed on Various Donor Portal Pages ==
   const { environmentConfig } = React.useContext(EnvironmentContext);
   const { donations } = useContext<IDonationContext>(DonationContext);
   const { isLoading } = useContext(CommonContext);

   return (
      <>
         {donations.length ? (
            // == SCREDP-95 Allow Admin Customization of Text Displayed on Various Donor Portal Pages ==
            <>
               <Tooltip target="#donationHistoryRiInformationFill" />
               <Section
                  header={{
                     title: "Donation History",
                  }}
                  icon={
                     <RiInformationFill 
                        id="donationHistoryRiInformationFill"
                        data-pr-tooltip={
                           isStringNullUndefinedOrEmpty(environmentConfig?.donationHistoryMessage)
                              ?"Donation History"
                              : environmentConfig?.donationHistoryMessage
                        }
                        fill="white" 
                        size={20} 
                     />
                  }
               >
                  <MyDonationsFilters />
                  <MyDonationSlots />
               </Section>
            </>
         ) : null}
         {!isLoading && !donations.length && <DonationNotFound />}
      </>
   );
}
