import styled from "styled-components";
import { PRIMARY_COLORS } from "../../../../consts/colors";

export const SwitchSectionDiv = styled.div`
   width: auto;
   border-right: 1px solid rgba(0, 0, 0, 0.175);
   background-color: white !important;
   box-shadow: 10px 3px 15px 0px rgb(0 0 0 / 28%);

   .image-section {
      display: flex;
      align-items: center;
      gap: 20px;
      .label-css {
         font-weight: bold;
      }
   }
   .vertical-line-css {
      height: 80px;
      border-left: 2px solid ${PRIMARY_COLORS.LINK_WATER};
   }
   .each-switch-div {
      pointer-events: none;
      margin-top: 5px;
      .form-check-input:checked {
         background-color: ${PRIMARY_COLORS.FRESH_GREEN} !important;
         border-color: ${PRIMARY_COLORS.FRESH_GREEN} !important;
      }
      .form-check-label {
         margin-left: 15px;
      }
   }
   &.mobile-css {
      display: flex;
      flex-direction: column;
      .image-section {
         justify-content: center;
         border-top: 2px solid ${PRIMARY_COLORS.LINK_WATER};
         border-bottom: 2px solid ${PRIMARY_COLORS.LINK_WATER};
         padding: 10px;
         .unsubscribe-image-css {
            width: 20px;
            height: 20px;
         }
         .business-image-css {
            width: 35px;
            height: 35px;
         }
         .cellPh-image-css {
            width: 25px;
            height: 25px;
         }
         .home-image-css {
            width: 28px;
            height: 28px;
         }
      }
      .row {
         padding: 20px 45px;
      }
   }
   &.desktop-css {
      display: grid;
      grid-template-columns: 180px 30px auto;
      align-items: center;
      gap: 20px;
      .image-section {
         padding: 30px;
         padding-right: 0px;
         .unsubscribe-image-css {
            width: 40px;
            height: 30px;
         }
         .business-image-css {
            width: 55px;
            height: 50px;
         }
         .cellPh-image-css {
            width: 40px;
            height: 40px;
         }
         .home-image-css {
            width: 40px;
            height: 40px;
         }
      }
   }
`;
