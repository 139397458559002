import React, { ReactElement } from "react";

export interface ISectionHeaderProps {
   title?: string;
   icon?: ReactElement;
}

export function SectionHeader(props: ISectionHeaderProps): ReactElement {
   return (
      <div className={"section-header"}>
         <>
            {/* SCREDP-95 Allow Admin Customization of Text Displayed on Various Donor Portal Pages */}
            {props.title && <h4>{props.title}{props.icon && props.icon}</h4>}
         </>
      </div>
   );
}
