import { format } from "date-fns";
import { IDonorDonationDTO } from "services/bexWISE/donorDataService/IDonorDataService";

export const isCurrentDateEqualToPreviousDate = (
   date: string | Date,
   index: number,
   appointmentDetails: IDonorDonationDTO[],
): boolean => {
   const currentDate: string = format(new Date(date), "yyyy");
   const prevDate: string | false =
      index !== 0 &&
      format(new Date(appointmentDetails[index - 1].date), "yyyy");
   return currentDate === prevDate;
};

export const getUniqueDonationProcedure = (
   donations: IDonorDonationDTO[],
): string[] | [] => {
   const uniqueProcedureDescriptions = [
      ...new Set(
         donations.map(
            (donation: IDonorDonationDTO) =>
               donation?.donationProcedureDescription,
         ),
      ),
   ];
   if (uniqueProcedureDescriptions.length) {
      return uniqueProcedureDescriptions;
   }
   return [];
};

export const getUniqueDonationCreditTo = (
   donations: IDonorDonationDTO[],
): string[] => {
   const uniqueProcedureDescriptions = [
      ...new Set(
         donations.map(
            (donation: IDonorDonationDTO) => donation?.creditToLocation?.name,
         ),
      ),
   ];

   uniqueProcedureDescriptions.sort(function (a: string, b: string) {
      if (a < b) {
         return -1;
      }
      return 1;
   });

   return uniqueProcedureDescriptions;
};
// == SCREDP-95 Allow Admin Customization of Text Displayed on Various Donor Portal Pages ==
export const isStringNullUndefinedOrEmpty = (
   value: string | undefined,
): boolean => {
   return  (
      value==null
         ||
      value==undefined
         ||
      value?.trim()==""
   )

};
