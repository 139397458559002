import FullScreenLoader from "app/components/FullScreenLoader";
import React, { useContext, useState } from "react";
import { EnvironmentContext } from "../environment/environmentContext";
import { NavigatorStatus } from "app/consts";

export interface CommonContextType {
   isLoading: boolean;
   startLoading: () => void;
   stopLoading: () => void;
   defaultSearchOrigin: string | undefined | null;  //SCREDP-87 Include all of Zip and Allow Street Address as Default
   currentOriginFromBrowser: string;
   navigationStatus: string;
}

interface CommonContextProps {
   children: React.ReactNode;
}

const initialState: CommonContextType = {
   isLoading: false,
   startLoading: () => { },
   stopLoading: () => { },
   defaultSearchOrigin: null ,  //SCREDP-87 Include all of Zip and Allow Street Address as Default
   currentOriginFromBrowser: "",
   navigationStatus: "",
};

export const CommonContext =
   React.createContext<CommonContextType>(initialState);

export const CommonContextProvider: React.FC<CommonContextProps> = ({
   children,
}) => {
   const { environmentConfig } = useContext(EnvironmentContext);
   const [isLoading, setShowLoader] = useState<boolean>(false);
   //SCREDP-87 Include all of Zip and Allow Street Address as Default
   const [defaultSearchOrigin, setDefaultSearchOrigin] = useState<string | undefined>();
   const [currentOriginFromBrowser, setCurrentOriginFromBrowser] = useState<string>("");
   const [navigationStatus, setNavigationStatus] = useState<string>("");

   const startLoading = (): void => {
      setShowLoader(true);
   };

   const stopLoading = (): void => {
      setShowLoader(false);
   };

   const getCurrentPosition = () => {
      navigator.geolocation.getCurrentPosition(
         position => {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;
            //get location from position (SCREDP-44)
            setCurrentOriginFromBrowser(latitude + "," + longitude);
         },
         error => {
            console.log("Geolocation error:", error);
            if (error.code === 1) {
               //SCREDP-87 Include all of Zip and Allow Street Address as Default
               setDefaultSearchOrigin(environmentConfig?.defaultSearchOrigin);
            }
         }
      );
   }

   React.useEffect(() => {
      let geolocationEventListener: (event: any) => void;

      const handleGeolocationPermission = () => {
         navigator.permissions
            .query({ name: "geolocation" })
            .then(function (result) {
               setNavigationStatus(result.state);
               if (result.state === NavigatorStatus.DENIED) {
                  // User has denied, but re-prompt for permission
                  navigator.geolocation.getCurrentPosition(
                     () => {
                        setNavigationStatus("granted");
                        getCurrentPosition();
                     },
                     () => {
                        setNavigationStatus("denied");
                        //SCREDP-87 Include all of Zip and Allow Street Address as Default
                        setDefaultSearchOrigin(environmentConfig?.defaultSearchOrigin);
                     }
                  );
               } else if (result.state === NavigatorStatus.GRANTED) {
                  setNavigationStatus("granted");
                  getCurrentPosition();
               } else {
                  // User hasn't made a decision; prompt for permission
                  navigator.geolocation.getCurrentPosition(
                     () => {
                        setNavigationStatus("granted");
                        getCurrentPosition();
                     },
                     () => {
                        setNavigationStatus("denied");
                        //SCREDP-87 Include all of Zip and Allow Street Address as Default
                        setDefaultSearchOrigin(environmentConfig?.defaultSearchOrigin);
                     }
                  );
               }
            })
            .catch(err => {
               console.log("Geolocation is not supported. ", err);
            });
      };

      // Initial permission check
      handleGeolocationPermission();

      // Add listener for permission changes
      geolocationEventListener = function (event) {
         handleGeolocationPermission();
      };

      // Add an event to always show the location icon on the browser (SCREDP-44)
      navigator.permissions
         .query({ name: "geolocation" })
         .then(result => {
            result.addEventListener("change", geolocationEventListener);
         });

      // Cleanup: Remove the event listener when the component is unmounted
      return () => {
         if (geolocationEventListener) {
            navigator.permissions
               .query({ name: "geolocation" })
               .then(result => {
                  result.removeEventListener("change", geolocationEventListener);
               });
         }
      };
   }, []);

   return (
      <CommonContext.Provider
         value={{
            isLoading,
            startLoading,
            stopLoading,
            defaultSearchOrigin, //SCREDP-87 Include all of Zip and Allow Street Address as Default
            currentOriginFromBrowser,
            navigationStatus,
         }}
      >
         {isLoading && <FullScreenLoader />}
         {children}
      </CommonContext.Provider>
   );
};
